import React from "react";
import loader from "../../../assets/images/Ellipsis.svg";

const DefaultLoadingPage = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: "100vw",
        height: "70vh",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={loader} alt="Loader" />
    </div>
  );
};
export default DefaultLoadingPage;
