// import React, { useEffect } from "react";
import "./SidebarRow.css";
import { Avatar } from "@material-ui/core";
// import defaultDP from "../../assets/images/user.png";

const SidebarProfile = ({
  src,
  Icon,
  title,
  accountCompletionPercentage,
  user,
}) => {
  return (
    <>
      <div className="sidebarRow ">
        {<Avatar src={src} />}
        {/* {Icon && <Icon />} */}
        <div className="text-white font-weight-bold text-capitalize ml-2">
          {title || ""}
        </div>
      </div>
      {(user.role_id == 4 || user.role_id == 5) && (
        <div className="row ml-1">
          <div className="col-9 m-auto">
            <div className="bg-secondary card-1">
              <div
                className="progress-bar "
                role={"progressbar"}
                style={{
                  width: `${accountCompletionPercentage}%`,
                  height: 3,
                  backgroundColor: "rgb(30 194 39 / 80%)",
                  boxShadow: "0 0 10px hsl(0deg 0% 100% / 70%)",
                }}
                aria-valuenow={"25"}
                aria-valuemin={"0"}
                aria-valuemax={"100"}
              ></div>
            </div>
          </div>
          <div
            className="col-3 text-white p-0"
            style={{ opacity: 0.6, fontSize: 12 }}
          >
            {accountCompletionPercentage}%
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarProfile;
