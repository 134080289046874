import { Provider } from "react-redux";
import store from "./store/Store";
import "./assets/css/argon-design-system-react.css";
import Content from "./components/content";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => (
  <Provider store={store}>
    <Router>
      <Content />
    </Router>
  </Provider>
);

export default App;
