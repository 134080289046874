import moment from "moment";
import { biodata_assential_fields } from "./constants";

export const showHeader_URL = [
  "/payment-callback",
  "/upgrade-plans",
  "/order-summary",
  "/feed",
  "/profile",
  "/my-profile",
  "/user-profile",
  "/activity",
  "/messanger",
  "/interest/sent",
  "/interest/received",
  "/activity/view-by-me",
  "/activity/view-by-other",
  "/activity/blocked-profiles",
  "/activity/shortlist",
  "/create-profile",
  "/edit-profile",
  "/biodata-maker",
  "/user-sql",
  "/user-mongo",
  "/payment-register",
  "/partner-dashboard",
  "/message-box",
  "/profile-settings",
];

export const prettyPrice = (value, decimal = true, roundUp = false) => {
  if (Number(value) === 0) {
    return "0";
  }
  if (!Number(value)) return "NaN";
  if (roundUp) {
    value = Math.ceil(value);
  }
  return (
    "₹" +
    new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: decimal ? 2 : 0,
      maximumFractionDigits: decimal ? 2 : 0,
    }).format(Number(value?.toFixed(decimal ? 2 : 0)))
  );
};

export const RoleMapping = {
  1: "Root",
  2: "Superadmin",
  3: "Agent",
  4: "Premium member",
  5: "Member",
  6: "Guest",
};

export function AgeToDateRange(fromAge, toAge) {
  let fromDate;
  let toDate;

  if (fromAge && toAge) {
    fromDate = moment().add(-fromAge, "years");
    toDate = moment().add(-toAge, "years");
  }
  return { fromDate, toDate };
}

export const CM2FootInch = (cm = 120) => {
  const length = cm / 2.54;
  return `${Math.floor(length / 12)}ft ${(
    length -
    12 * Math.floor(length / 12)
  ).toFixed(0)}in `;
};

export const findAccountCompletionPercentage = (data) => {
  if (data) {
    // data = {name:"", email: '', }
    try {
      let keysOfData = biodata_assential_fields;
      let numberOfKeys = keysOfData.length;

      let completionCount = 0;

      keysOfData.map((item) => {
        if (Array.isArray(data[item])) {
          data[item].length && completionCount++;
        } else {
          if (data[item]) {
            completionCount++;
          }
        }
        return false;
      });
      if (numberOfKeys > 0) {
        if (numberOfKeys < 20) {
          // default data reading from myAccount
          return parseFloat(((completionCount / 25) * 100).toFixed(2));
        }
        return parseFloat(((completionCount / numberOfKeys) * 100).toFixed(2));
      } else {
        return 0;
      }
    } catch (e) {
      console.log("error in calculation of account completion status", e);
      return 0;
    }
  }
};
