import { useState, useEffect } from "react";
import { ListGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SidebarProfile from "../../components/commonComponent/SidebarUserInfo";
import "../../assets/css/light-bootstrap-dashboard.css";
import { postToGetList } from "../../components/crud";
import { newMessageFromWatchdog } from "../../reducers/messanger/actions";
import { setUnreadMessageIcon } from "../../reducers/notification/action";
import { setAccessList } from "../../reducers/accessList/access.action";
import { setCookie } from "../../components/authentication/cookieManager";
import logo2 from "../../assets/images/logo.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActivityMenu, All_Page, InterestMenu } from "./constant";
import styled from "styled-components";
import usePlatform from "../../customHooks/usePlatform";

const SideBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showInterests, setShowInterest] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [userName, setUserName] = useState("");
  const [DP, setDP] = useState("");
  const [collapseClasses, setCollapseClasses] = useState("");
  const [iswatchdogRunning, setIswatchdogRunning] = useState(false);

  const platform = usePlatform();

  const ReduxAccess = useSelector((state) => state?.user?.access || []);
  var access =
    ReduxAccess.length > 0
      ? ReduxAccess
      : JSON.parse(localStorage.getItem("menu_access"));

  const startLoopFunction = async () => {
    let isPaid = await props.user.paid;
    if (isPaid === "true") {
      loopFunction();
      setIswatchdogRunning(true);
    }
  };

  const handleNavigate = (url) => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
    navigate(url);
  };

  useEffect(() => {
    if (props.user.paid && !iswatchdogRunning) {
      startLoopFunction();
    }
  }, [props.user?.paid]);

  const loopFunction = async () => {
    let params = { id: props.user.id };
    postToGetList("chat/watchdog/ws", params)
      .then((resp) => {
        if (resp.data.data.status) {
          if (resp.data.data.c.status) {
            props.newMessageFromWatchdog(resp.data.data.c);
            resp.data.data.c.data.map((item) => {
              props.setUnreadMessageIcon(item.from, "add");
            });
          }
        }
      })
      .catch((e) => console.log("error in watchdog", e));
    await sleep(30000);
    return loopFunction();
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (props.userDetailed?.profile) {
      try {
        if (props.userDetailed.profile.firstName) {
          setUserName(
            props.userDetailed.profile.firstName +
              " " +
              (props.userDetailed.profile?.middleName
                ? props.userDetailed.profile?.middleName
                : "") +
              " " +
              (props.userDetailed.profile?.lastName
                ? props.userDetailed.profile?.lastName
                : "")
          );
        }

        if (props.userDetailed.profile.photos) {
          setDP(props.userDetailed.profile?.photos[0]?.image || "");
        }
      } catch (e) {
        console.log("error in sidebar setname", e);
      }
    }
  }, [props.userDetailed, props.userDetailed?.profile]);

  useEffect(() => {
    props.toggleSidebar
      ? setCollapseClasses("sidebar-show")
      : setCollapseClasses("");
  }, [props.toggleSidebar]);

  const logoutMe = () => {
    setCookie("access_token", null, 2);
    setCookie("role", null, 2);
    // successful_logout();
  };

  return (
    <div className={`sidebar ${collapseClasses}`}>
      <MenuWrapper
        className="main-sidebar sidebar-wrapper shadow sidebar-bg-gradient1"
        platform={platform}
      >
        <div className="logo">
          <div onClick={() => handleNavigate("/feed")}>
            <div className="d-flex justify-content-center">
              <img
                src={logo2}
                alt="yogyarishte.com a matrimony site for you"
                style={{ width: platform === "mobile" ? "50%" : "70%" }}
              />
            </div>
          </div>
        </div>

        <div className="profile mt-3 mb-3">
          <Link
            to={
              props.user?.role_id == 3 ? "#" : "/user-profile/" + props.user?.id
            }
            className="nav-link p-0"
          >
            <SidebarProfile
              src={DP && `${DP}`}
              title={userName ? userName : props.user?.name}
              accountCompletionPercentage={props.accountCompletionPercentage}
              role={props.user.role}
              user={props.user}
            />
          </Link>
        </div>
        <div className="nav-wrapper">
          <ul className="nav flex-column nav-tab  ">
            {(access || [])
              ?.filter((i) => i.access_slug != "user_profile")
              .map((menu, index) => {
                if (menu.access_slug == "activity") {
                  return (
                    <li
                      key={index}
                      className={
                        menu.url === location.pathname
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() => setShowActivity(!showActivity)}
                        style={{
                          cursor: "pointer",
                          marginLeft: 10,
                          height: 45,
                          borderBottom: "1px solid rgba(255,255,255,.2)",
                        }}
                      >
                        <span className="text-white" style={{ opacity: 0.8 }}>
                          My Activity{" "}
                          <div
                            className={
                              showActivity
                                ? "fa fa-caret-right right mt-1"
                                : "fa fa-caret-down right mt-1"
                            }
                          ></div>
                        </span>
                      </div>
                      {showActivity ? (
                        <ListGroup>
                          {ActivityMenu.map((menu, index) => (
                            <li
                              className={
                                All_Page.find((i) => i.url === menu.url).url ===
                                location.pathname
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                              key={index}
                            >
                              <a
                                className="nav-link cursor-pointer"
                                onClick={() => handleNavigate(menu.url)}
                              >
                                <MenuText>{menu.lable}</MenuText>
                              </a>
                            </li>
                          ))}
                        </ListGroup>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                } else if (menu.access_slug == "interest") {
                  return (
                    <li
                      key={index}
                      className={
                        props.activeDisplayTitle === "Interest Sent" ||
                        props.activeDisplayTitle === "Interest Received"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() => setShowInterest(!showInterests)}
                        style={{
                          cursor: "pointer",
                          marginLeft: 10,
                          height: 45,
                          borderBottom: "1px solid rgba(255,255,255,.2)",
                        }}
                      >
                        <span className="text-white">
                          Interest{" "}
                          <div
                            className={
                              showInterests
                                ? "fa fa-caret-right right"
                                : "fa fa-caret-down right"
                            }
                          ></div>
                        </span>
                      </div>
                      {showInterests && (
                        <ListGroup>
                          {InterestMenu.map((menu, index) => (
                            <li
                              key={index}
                              className={
                                All_Page.find((i) => i.url === menu.url).url ===
                                location.pathname
                                  ? "nav-item active"
                                  : "nav-item"
                              }
                            >
                              <a
                                onClick={() => handleNavigate(menu.url)}
                                className="nav-link cursor-pointer"
                              >
                                <MenuText>{menu.lable}</MenuText>
                              </a>
                            </li>
                          ))}
                        </ListGroup>
                      )}
                    </li>
                  );
                }

                return (
                  <li
                    key={index}
                    className={
                      location.pathname === menu.url
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    {menu.access_slug === "biodata_maker" ? (
                      <a
                        href={"/public-biodata-maker"}
                        target="_blank"
                        className="nav-link p-0 cursor-pointer"
                      >
                        <MenuText>{menu.title}</MenuText>
                      </a>
                    ) : (
                      <a
                        onClick={() => handleNavigate(menu.url)}
                        className="nav-link p-0 cursor-pointer"
                      >
                        <MenuText>{menu.title}</MenuText>
                      </a>
                    )}
                  </li>
                );
              })}
            <li className="nav-item" onClick={logoutMe}>
              <Link to="/login" className="nav-link p-0">
                <MenuText>Log out</MenuText>
              </Link>
            </li>
          </ul>
        </div>
      </MenuWrapper>
    </div>
  );
};
const MenuText = styled.div`
  color: white;
  margin-left: 10px;
  text-transform: capitalize;
  box-sizing: border-box;
`;
const MenuWrapper = styled.div`
  font-size: ${({ platform }) => (platform === "mobile" ? "14px" : "15px")};
`;
const mapStateToProps = (state) => {
  return {
    activeDisplayTitle: state.user.activeDisplayTitle,
    user: state.user.userAccount,
    userDetailed: state.user.userData?.allData,
    accountCompletionPercentage: state.user.accountCompletionPercentage,
    toggleSidebar: state.notification.toggleSidebar,
    accessList: state.menuAccessList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  newMessageFromWatchdog: (message) =>
    dispatch(newMessageFromWatchdog(message)),
  setUnreadMessageIcon: (id, type) => dispatch(setUnreadMessageIcon(id, type)),
  setAccessList: (list) => dispatch(setAccessList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
