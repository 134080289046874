import styled from "styled-components";
import DemoNavbar from "../DemoNavBar";
import SimpleFooter from "../SimpleFooter";
import logo from "../../assets/images/logo.svg";
import { Container } from "@mui/material";

const AboutUs = () => {
  return (
    <>
      <DemoNavbar />
      <SectionAbout>
        <Container>
          <AboutusText>About Us</AboutusText>
          <AboutP>
            We help searching right partner for you that you really deserve.
          </AboutP>
          <AboutInfo>
            <AboutImg>
              <img src={logo} alt="logo" />
            </AboutImg>
            <p>
              {" "}
              Yogyarishte.com is a leading platform that provides a space for
              individuals seeking a life partner. We place a strong emphasis on
              ensuring the authenticity of profiles. Our goal is to ensure that
              every deserving individual finds the right partner without
              compromise. Platforms like yogyarishte.com significantly expand
              your reach, breaking the boundaries of traditional partner search
              methods. Here, you have unlimited options to find the right
              partner for you. So, never settle for less in life; join us today.
            </p>
          </AboutInfo>
          <div>
            Team: yogyarishte.com
          </div>

          <div>
            Contact: yogyarishte.outlook.com
          </div>
        </Container>
      </SectionAbout>
      <SimpleFooter />
    </>
  );
};
const SectionAbout = styled.section`
  background: rgb(224, 251, 222);
  background: linear-gradient(
    360deg,
    rgb(245, 255, 245) 0%,
    rgb(173, 252, 163) 100%
  );
  padding: 100px 0 20px 0;
  text-align: center;
`;
const AboutusText = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;
const AboutP = styled.p`
  font-size: 1rem;
  color: #323030;
  max-width: 800px;
  margin: 0 auto;
`;
const AboutInfo = styled.div`
  margin: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 30px;
  flex-wrap: wrap;
  p {
    font-size: 1rem;
    margin: 0 2rem;
    text-align: justify;
  }
`;
const AboutImg = styled.div`
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
  }
`;
export default AboutUs;
