export const All_Page = [
  {
    name: "feed",
    lable: "Feed",
    url: "/feed",
  },
  {
    name: "Messanger",
    lable: "Chat",
    url: "/message-box",
  },
  {
    name: "profile",
    lable: "Profile",
    url: "/user-profile",
  },
  {
    name: "ViewByOther",
    lable: "Seen my profile",
    url: "/activity/view-by-other",
  },
  { name: "ViewByMe", lable: "Profile i seen", url: "/activity/view-by-me" },
  { name: "Short Listed", lable: "Shortlisted", url: "/activity/shortlist" },
  {
    name: "Blocked Profiles",
    lable: "Blocked",
    url: "/activity/blocked-profiles",
  },
  {
    name: "Interest Sent",
    lable: "Interest Sent",
    url: "/interest/sent",
  },
  {
    name: "Interest Received",
    lable: "Interest Received",
    url: "/interest/received",
  },
  {
    name: "Biodata Maker",
    lable: "Biodata Maker",
    url: "/public-biodata-maker",
  },
  {
    name: "Upgrade",
    lable: "Upgrade",
    url: "/upgrade-plans",
  },
  {
    name: "profile-settings",
    lable: "Profile settings",
    url: "/profile-settings",
  },
  {
    name: "partner-dashboard",
    lable: "Dashboard",
    url: "/partner-dashboard",
  },
];

export const ActivityMenu = All_Page.filter((item) =>
  ["ViewByOther", "ViewByMe", "Blocked Profiles", "Short Listed"].includes(
    item.name
  )
);
export const InterestMenu = All_Page.filter((item) =>
  ["Interest Sent", "Interest Received"].includes(item.name)
);
