const initialState = {
  subscriptionDetail: {
    role: 6,
    role_name: "guest",
    allowed_components: ["feed"],
  },
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUBSCRIPTION": {
      return {
        ...state,
        subscriptionDetail: { ...state.subscriptionDetail, ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
