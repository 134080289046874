import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import Headroom from "headroom.js";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Button } from "@mui/material";
import styled from "styled-components";
// import { Home } from "@material-ui/icons";

const NavBar = () => {
  const [collapseClasses, setCollapseClasses] = useState("");
  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
  }, []);
  const MobileNavBar = () => {
    return (
      <>
        {window.location.pathname === "/login" ? (
          <NavItem className="navbar-toggler">
            <NavLink to="/signup-me/" tag={Link}>
              <Button size="small" sx={{ fontWeight: 500, color: "black" }}>
                Sign up
              </Button>
            </NavLink>
          </NavItem>
        ) : (
          <NavItem className="navbar-toggler">
            <div style={{ display: "flex", gap: "10px" }}>
              <Link to="/login">
                <LoginButton>Login</LoginButton>
              </Link>
              <Link to="/signup-me">
                <LoginButton>Sign Up</LoginButton>
              </Link>
            </div>
          </NavItem>
        )}
      </>
    );
  };
  return (
    <div className="header-global">
      <Navbar
        className="navbar-main navbar-transparent  headroom"
        expand="lg"
        id="navbar-main"
      >
        <Container className="container-custom">
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img
              src={logo}
              alt={`${process.env.REACT_APP_APP_NAME} - a matrimony site for you`}
            />
          </NavbarBrand>

          {MobileNavBar()}

          <UncontrolledCollapse
            toggler="#navbar_global"
            navbar
            className={collapseClasses}
          >
            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
              <div className="navbar-collapse-header">
                <Row className="m-0">
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      {/* <img
                      alt="..."
                      src={require("assets/img/brand/argon-react.png")}
                    /> */}
                      {process.env.REACT_APP_APP_NAME}
                    </Link>
                  </Col>
                  <Col
                    className="collapse-close d-flex justify-content-end"
                    xs="6"
                  >
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>

              {/* <NavItem>
                <NavLink to="/" tag={Link}>
                  <div className="btn">
                    <Home style={{ color: "red" }} />
                  </div>
                </NavLink>
              </NavItem> */}
            </Nav>
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem>
                <Link to="/partner/home">
                  <Button size="small" sx={{ fontWeight: 600, color: "black" }}>
                    Become A Partner
                  </Button>
                </Link>
              </NavItem>
              <NavItem className="d-none d-lg-block">
                <Link to="/login">
                  <Button size="small" sx={{ fontWeight: 600, color: "black" }}>
                    Login
                  </Button>
                </Link>
              </NavItem>
              <NavItem className="d-none d-lg-block ">
                <Link to="/signup-me/">
                  <Button size="small" sx={{ fontWeight: 600, color: "black" }}>
                    Sign-Up
                  </Button>
                </Link>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </div>
  );
};

const LoginButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: black;
`;

export default NavBar;
