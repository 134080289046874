import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import DefaultLoadingPage from "./components/commonComponent/LoadingSpinner/defaultLoadingPage";
import AboutUs from "./components/aboutUs";
const ProtectedRoute = lazy(() =>
  import("./components/authentication/protectedRoute")
);
const PartnerHome = lazy(() => import("./components/partner/PartnerHome"));
const ForgotPassword = lazy(() =>
  import("./components/authentication/forgotPassword")
);
const EmailConfirm = lazy(() =>
  import("./components/authentication/EmailConfirm")
);
const PartnerSignup = lazy(() =>
  import("./components/authentication/PartnerSignup")
);
const NoAccessProfile = lazy(() => import("./components/profile/noAccess"));
const PasswordReset = lazy(() =>
  import("./components/authentication/PasswordReset")
);
// import {SimpleSpinner} from './components/commonComponent/LoadingSpinner/loadingSpinner.component'
const ProfileRouter = lazy(() => import("./components/profile/profileRouter"));
const myProfileRouter = lazy(() =>
  import("./components/profile/myProfileRouter")
);
const ChatShell = lazy(() =>
  import("./components/Messanger/containers/shell/ChatShell")
);
const ChatRoom = lazy(() =>
  import("./components/Messanger/containers/shell/chatRoom")
);
const InterestSent = lazy(() => import("./components/interest/interestSent"));
const InterestReceived = lazy(() =>
  import("./components/interest/interestReceived")
);
const ViewByMe = lazy(() => import("./components/activity/ViewByMe"));
const ViewByOther = lazy(() => import("./components/activity/ViewByOther"));
const BlockedProfiles = lazy(() => import("./components/activity/Blocked"));
const ShortListed = lazy(() => import("./components/activity/ShortListed"));
const PaymentCallbackPage = lazy(() =>
  import(
    "./components/authentication/payment_pages/paymentCallback/PaymentCallBackPage"
  )
);
const PaymentPage = lazy(() =>
  import("./components/authentication/payment_pages/selectPlan")
);
const Ordersummary = lazy(() =>
  import("./components/authentication/payment_pages/orderSummary")
);
const EmailPhoneVarification = lazy(() =>
  import("./components/authentication/signup/emailPhoneVarification")
);
const CreateNewProfile = lazy(() =>
  import("./components/profile/createProfile")
);
const editProfile = lazy(() =>
  import("./components/profile/editProfile/index")
);
// const MyProfile = lazy(() => import("./components/profile/myProfile"));
const bioDataMaker = lazy(() =>
  import("./components/biodata_maker/bioDataMaker")
);
const Blog = lazy(() => import("./components/blog/blog"));
const AddBlog = lazy(() => import("./components/blog/addBlog"));
const Contact = lazy(() => import("./components/home/contact_us/contact_us"));
const ReadBlog = lazy(() => import("./components/blog/readBlog"));
const EditBlog = lazy(() => import("./components/blog/editBlog"));
const AllUsers = lazy(() => import("./components/admin/allUserV2"));
const AllUsersMongo = lazy(() => import("./components/admin/allUsersMongo"));
const PaymentRegister = lazy(() =>
  import("./components/admin/paymentRegister")
);
const PartnerDashboard = lazy(() =>
  import("./components/partner/PartnerDashboard")
);
const ProfileSettings = lazy(() =>
  import("./components/authentication/profileSettings")
);
const Error404 = lazy(() => import("./components/home/404"));

const Home = lazy(() => import("./components/home"));
const Login = lazy(() => import("./components/authentication/login"));
const Feed = lazy(() => import("./components/Feed/Feed"));
const SignupSingle = lazy(() =>
  import("./components/authentication/signup/SignupSingle")
);
const PublicBiodata = lazy(() =>
  import("./components/biodata_maker/publicBiodata")
);

//  protected means login required
const pageList = [
  { isProtected: false, path: "/", component: Home },
  { isProtected: false, path: "/aboutus", component: AboutUs },
  { isProtected: false, path: "/partner/home", component: PartnerHome },
  { isProtected: false, path: "/login", component: Login },
  { isProtected: false, path: "/forgot-password", component: ForgotPassword },
  {
    isProtected: false,
    path: "/emailconfirm/:uid/:token",
    component: EmailConfirm,
  },
  {
    isProtected: false,
    path: "/emailverify",
    component: EmailPhoneVarification,
  },
  { isProtected: false, path: "/signup-me/", component: SignupSingle },
  { isProtected: false, path: "/signup-me/:refer", component: SignupSingle },
  { isProtected: false, path: "/partner-signup", component: PartnerSignup },
  {
    isProtected: false,
    path: "/public-biodata-maker",
    component: PublicBiodata,
  },
  {
    isProtected: false,
    path: "/resetpassword/:uid/:token",
    component: PasswordReset,
  },
  { isProtected: false, path: "/blog", component: Blog },
  { isProtected: false, path: "/blog/read/:id", component: ReadBlog },
  { isProtected: false, path: "/blog/edit/:id", component: EditBlog },
  { isProtected: false, path: "/contact-us", component: Contact },

  { isProtected: true, path: "/feed", component: Feed },
  { isProtected: true, path: "/profile-settings", component: ProfileSettings },
  { isProtected: true, path: "/my-profile/:id", component: myProfileRouter },
  {
    isProtected: true,
    path: "user-profile/:id?/edit-profile",
    component: editProfile,
  },
  { isProtected: true, path: "/user-profile/:id", component: ProfileRouter },
  { isProtected: false, path: "/user-profile/9xx", component: NoAccessProfile },
  { isProtected: true, path: "/message-box", component: ChatShell },
  { isProtected: true, path: "/message-box/chat", component: ChatRoom },
  { isProtected: true, path: "/interest/sent", component: InterestSent },
  {
    isProtected: true,
    path: "/interest/received",
    component: InterestReceived,
  },
  { isProtected: true, path: "/activity/view-by-me", component: ViewByMe },
  {
    isProtected: true,
    path: "/activity/view-by-other",
    component: ViewByOther,
  },
  {
    isProtected: true,
    path: "/activity/blocked-profiles",
    component: BlockedProfiles,
  },
  { isProtected: true, path: "/activity/shortlist", component: ShortListed },
  { isProtected: true, path: "/create-profile", component: CreateNewProfile },

  {
    isProtected: true,
    path: "/upgrade-plans/order-summary",
    component: Ordersummary,
  },
  {
    isProtected: true,
    path: "/upgrade-plans/payment/fallback",
    component: PaymentCallbackPage,
  },
  { isProtected: true, path: "/user-sql", component: AllUsers },
  { isProtected: true, path: "/user-mongo", component: AllUsersMongo },
  { isProtected: true, path: "/payment-register", component: PaymentRegister },
  { isProtected: true, path: "/upgrade-plans", component: PaymentPage },
  { isProtected: true, path: "/add-blog", component: AddBlog },

  { isProtected: false, path: "/biodata-maker", component: bioDataMaker },
  {
    isProtected: true,
    path: "/partner-dashboard",
    component: PartnerDashboard,
  },
];
const RouteConfig = (props) => (
  <Suspense fallback={<DefaultLoadingPage />}>
    <Routes>
      {pageList.map((item, index) =>
        item.isProtected ? (
          <Route
            key={index}
            exact
            path={item.path}
            element={
              <ProtectedRoute
                component={item.component}
                {...props}
                path={item.path}
              />
            }
          />
        ) : (
          <Route
            key={index}
            exact
            path={item.path}
            element={<item.component />}
          />
        )
      )}
      <Route path="*" element={Error404} state={"state"} params={{ a: 3 }} />
    </Routes>
  </Suspense>
);

export default RouteConfig;
