

const initialState={
    messageNotification:[],
    sidbarNotification:[],
    toggleSidebar:false,
    toastNotification:{
        message:'',
        position:'',
        type:'', // info, success, warning, error, default
        theme:'', // light, dark, colored,
        isShow:false
    }
}
const notificationReducer=(state= initialState, action)=>{
    switch(action.type){
        case 'SET_UNREAD_MESSAGE_ICON' :{
            // if add or remove            
            let allNotif = state.messageNotification
            if(action.category==='add'){                
                allNotif.push({id: action.id})
                return {...state, messageNotification:allNotif }
            }
            if(action.category==='remove'){                
                return ({...state, messageNotification:allNotif.filter(item=> item.id===action.id? false:true)})                
            }
            break;
        }
        case 'TOGGLE_SIDEBAR' :{
            return ({...state, toggleSidebar: !state.toggleSidebar})
        }
        case 'SET_TOAST_NOTIFICATION' :{
            return {...state, toastNotification: action.payload}
        }
        default :
            return state;
    }
}

export default notificationReducer