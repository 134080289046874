const initialState =[]

const accessListReducer = (state= initialState, action)=>{
    switch(action.type){
        case 'SET_ACCESSLIST':{
            return action.payload
        }
        default:
            return state
    }
}


export default accessListReducer