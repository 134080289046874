import { useEffect } from "react";
import RouteConfig from "../routerConfig";
import SideBar from "../UserComponents/layout/sidebar";
import DashboardNav from "../UserComponents/layout/DashboardNav";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showHeader_URL } from "./utils";

const Content = (props) => {
  const menuAccessList = useSelector((state) => state.user.access);
  const dispatch = useDispatch(null);
  useEffect(() => {
    if (
      menuAccessList.length < 1 &&
      Array.isArray(menuAccessList) &&
      JSON.parse(localStorage.getItem("menu_access"))
    ) {
      dispatch({
        type: "SET_MENU_ACCESS",
        payload: JSON.parse(localStorage.getItem("menu_access")) || [],
      });
    }
  }, []);
  return <MainContent {...props} />;
};

const MainContent = (props) => {
  return (
    <div className="container-fluid p-0">
      {!showHeader_URL.find((item) => {
        if (item === props.router?.location?.pathname) {
          return true;
        } else if (props.router?.location?.pathname.startsWith(item)) {
          return true;
        }
        return false;
      }) ? (
        <></>
      ) : (
        <>
          <SideBar id="sidebar" {...props} />
          <DashboardNav label={"Dashboard"} selectView {...props} />
        </>
      )}
      <RouteConfig {...props} />
    </div>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(Content);
