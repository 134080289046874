import { getList } from "../../components/crud.js";
import { Logout } from "../../components/authentication/Logout.js";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_ME_ACCOUNT = "GET_ME_ACCOUNT";
export const GET_ALL_USER = "GET_ALL_USER";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_ACTIVE_DISPLAY_TITLE = "SET_ACTIVE_DISPLAY_TITLE";
export const GET_THIS_USER = "GET_THIS_USER";
export const UPDATE_ACTIVITY_STATUS = "UPDATE_ACTIVITY_STATUS";
export const SET_ACCOUNT_COMPLETION_PERCENTAGE =
  "SET_ACCOUNT_COMPLETION_PERCENTAGE";
export const GUEST_BIODATA = "GUEST_BIODATA";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_MENU_ACCESS = "SET_MENU_ACCESS";

export const getUserDetail =
  (queryString = "", param, dataBase) =>
  (dispatch) => {
    return getList("user/me/" + queryString, param)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_USER_DETAIL,
            payload: dataBase,
          });
          return data;
        } else if (data.status === 401) {
          Logout();
        }
      })
      .catch((e) => {
        console.log("error in data loading", e);
      });
  };

export const updateProfileInfo =
  (param = {}) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_PROFILE,
      payload: param,
    });

export const setMenuAccess = (data) => (dispatch) => {
  dispatch({ type: SET_MENU_ACCESS, payload: data });
};

export const setForceUserDetail = (data) => (dispatch) =>
  dispatch({
    type: GET_USER_DETAIL,
    payload: data,
  });

export const MeAccount = (data) => {
  return (dispatch) =>
    dispatch({
      type: GET_ME_ACCOUNT,
      payload: data,
    });
};

export const guestBiodata = (data) => {
  return (dispatch) =>
    dispatch({
      type: GUEST_BIODATA,
      payload: data,
    });
};

export const updateActivityStatus = (data) => (dispatch) => {
  return dispatch({
    type: UPDATE_ACTIVITY_STATUS,
    payload: data,
  });
};

export const getAllUser =
  (querryString = "", param) =>
  (dispatch) => {
    return getList("all-user/" + querryString, param).then((data) => {
      dispatch({
        type: GET_ALL_USER,
        payload: data.data,
      });
      return data;
    });
  };
export const getThisUser =
  (querryString = "", param, database) =>
  (dispatch) => {
    return dispatch({
      type: GET_THIS_USER,
      payload: [],
    });
  };

export const setSelectedUser =
  (users = []) =>
  (dispatch) => {
    return dispatch({
      type: SET_SELECTED_USER,
      payload: users,
    });
  };
export const setSelectedID = (id) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_ID,
    payload: id,
  });
};

export const setActiveDisplayTitle =
  (title = "") =>
  (dispatch) => {
    return dispatch({
      type: SET_ACTIVE_DISPLAY_TITLE,
      payload: title,
    });
  };

export const setAccountCompletionPercentage =
  (number = 0) =>
  (dispatch) => {
    return dispatch({
      type: SET_ACCOUNT_COMPLETION_PERCENTAGE,
      payload: number,
    });
  };
