import axios from "axios";
import { getCookie, setCookie } from "./authentication/cookieManager";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const token_key = getCookie("access_token");
// const CSRFToken = getCookie("csrftoken");

const options = {
  headers: {
    "Content-type": "application/json",
    Authorization: "Token " + token_key,
  },
  withCredentials: true,
  cancelToken: source.token,
};

// const BaseAddress = process.env.REACT_APP_BASE_URL
let BaseAddress = window.location.origin;
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  BaseAddress = "http://localhost:3001";
}

const BaseAddressAPI = BaseAddress + "/app/";
const BaseAddressAPIAdmin = BaseAddress + "/admin";
const BaseAddressAUTH = BaseAddress + "/auth";

const Paytm_payment_URL = process.env.REACT_APP_PAYTM_PAYMENT_URL;

export const getList = async (resource, data) => {
  try {
    const res = await axios.get(BaseAddressAPI + resource, {
      ...options,
      params: data,
    });

    return res;
  } catch (err) {
    console.log("error in c get ", err);
    if (err.response.status === 401) {
      setCookie("access_token", false, 0);
      setCookie("role", false, 0);
      setCookie("email", false, 0);
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
      return { status: 401 };
    }
  }
};
export const postToGetList = async (resource, data) => {
  try {
    const res = await axios.post(BaseAddressAPI + resource, data, options);
    return res;
  } catch (err) {
    // window.location.replace('/login')
    throw err;
  }
};
export const postAdmin = async (resource, data) => {
  try {
    const res = await axios.post(BaseAddressAPIAdmin + resource, data, options);
    return res;
  } catch (err) {
    // window.location.replace('/login')
    throw err;
  }
};

export const auth = async (resource) =>
  await axios.post(BaseAddressAUTH + "/login", resource, options);

export const signup_me = async (resource) => {
  try {
    let qry = "/sign-up";
    if (resource.ProfileFor == "p") qry = "/sign-up/partner";
    const res = await axios.post(BaseAddressAUTH + qry, resource, options);
    return res;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const authPost = async (url, payload) => {
  try {
    const res = await axios.post(BaseAddressAUTH + "/" + url, payload, options);

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const email_confirm_request = async (resource) => {
  try {
    const res = await axios.post(
      BaseAddressAUTH + "/confirm-account",
      resource,
      options
    );

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const forgotPassword_request = async (resource) => {
  try {
    const res = await axios.post(
      BaseAddressAUTH + "/forgot-password",
      resource,
      options
    );
    return res;
  } catch (err) {
    // return err;
    console.log("error>>>", err);
    return {};
  }
};

export const changepassword = async (resource) => {
  try {
    const res = await axios.post(
      BaseAddressAUTH + "/update-password",
      resource,
      options
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const Paytm_payment_post_req = async (data) => {
  try {
    // const url="https://securegw-stage.paytm.in/order/process";

    const res = await axios.post(Paytm_payment_URL, {
      params: data,

      headers: {
        "Content-Type": "application/json",
      },
      hostname: "securegw-stage.paytm.in",
    });

    return res;
  } catch (err) {
    console.log("error in c get ", err);
  }
};

export const getListWithToken = async (resource, data, token) => {
  try {
    const res = await axios.get(BaseAddressAPI + resource, {
      ...options,
      params: data,
    });

    return res;
  } catch (err) {
    console.log("error in c get ", err);
    if (err.response.status === 401) {
      return { status: 401 };
    }
  }
};

export const postWithoutToken = async (address, data) => {
  try {
    const res = await axios.post(BaseAddressAPI + address, data, options);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getAuthList = async (resource, data) => {
  try {
    return await axios.get(BaseAddressAUTH + resource, {
      ...options,
      params: data,
    });
  } catch (err) {
    console.log("error in c get ", err);
    if (err.response.status === 401) {
      return { status: 401 };
    }
  }
};

export const postAuthToGetList = async (resource, data) => {
  try {
    const res = await axios.post(BaseAddressAUTH + resource, data, options);
    return res;
  } catch (err) {
    console.log(err);
  }
};
