import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Instagram, Facebook } from "@mui/icons-material";
import usePlatform from "../customHooks/usePlatform";

const SimpleFooter = () => {
  const platform = usePlatform();

  return (
    <>
      <footer className="footer" style={{ backgroundColor: "#f0f2fc" }}>
        <Container className="container-custom">
          <Row className=" row-grid align-items-center mb-5">
            <Col lg="6">
              <div
                className=" mb-2"
                style={{ fontSize: platform === "mobile" ? "18px" : "20px" }}
              >
                Let's join hands and find a best match for you.
              </div>
              <div
                className={`mb-0`}
                style={{ fontSize: platform === "mobile" ? "16px" : "18px" }}
              >
                Thank you for reaching out to us!
              </div>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              Follow us on
              <IconButton
                href="https://www.instagram.com/yogyarishte"
                target="_blank"
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/profile.php?id=61560584018933&mibextid=LQQJ4d"
                target="_blank"
              >
                <Facebook />
              </IconButton>
            </Col>
          </Row>

          <Row>
            <Col md="6" style={{ minHeight: 250 }}>
              <Nav className="nav-footer" style={{ flexDirection: "column" }}>
                <NavItem className="mr-5">
                  {/* <NavLink href="#" target="_blank" >Pricing</NavLink> */}
                  <NavLink to="/blog" tag={Link} target="_blank">
                    Blog{" "}
                  </NavLink>
                </NavItem>
                <NavItem className="mr-5">
                  {/* <NavLink href="#" target="_blank" >Pricing</NavLink> */}
                  <NavLink to="/partner/home" tag={Link}>
                    Beacome a partner{" "}
                  </NavLink>
                </NavItem>
                <NavItem className="mr-5">
                  <NavLink href="/aboutus">About us</NavLink>
                  {/* <NavLink href="#" target="_blank" >Product Feedback</NavLink> */}
                </NavItem>
                <NavItem className="mr-5">
                  <NavLink to="/contact-us" target="_blank" tag={Link}>
                    Contact Us
                  </NavLink>
                  {/* <NavLink href="#" target="_blank" >Help Center</NavLink> */}
                </NavItem>
                <NavItem className="mr-5">
                  <NavLink
                    to="/public-biodata-maker"
                    target="_blank"
                    tag={Link}
                  >
                    Biodata maker
                  </NavLink>
                  {/* <NavLink href="#" target="_blank" >Help Center</NavLink> */}
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <hr />
          <Row className=" align-items-center justify-content-md-between">
            <Col md="6">
              <div className=" copyright">
                © {new Date().getFullYear()}{" "}
                <a href="/" target="_blank">
                  {process.env.REACT_APP_APP_NAME}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default SimpleFooter;
