const initState = {
  data: [],
  page: 1,
  next_page: 2,
  previous_page: 1,
  total_count: 100,
  size: 10,
  filter: [],
};

const searchProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "PROFILE_SEARCH": {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default searchProfileReducer;
