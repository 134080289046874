const initialState = {
  conversations: [],
  selectedConversation: {},
  selectType: "manual", // url: coming from profile card,  manual: select coming from chat list
};

// initialState.selectedConversation = initialState.conversations[0];

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_FRIEND": {
      if (action.payload) {
        let conv = state.conversations;
        conv = [...[action.payload[0]], ...conv];
        return {
          ...state,
          conversations: conv,
          selectedConversation: action.payload[0],
        };
      } else {
        return state;
      }
    }
    case "MY_FRIEND_LIST_FETCH": {
      let friendList = action.payload?.map((item) => ({
        ...item,
        isMessageFetched: false,
      }));

      return { ...state, conversations: friendList };
    }
    case "CONVERSATIONS_LOADED": {
      const newState = { ...state };
      newState.conversations = action.payload.conversations
        ? action.payload.conversations
        : [];
      newState.selectedConversation = action.payload.selectedConversation;
      return newState;
    }
    case "CONVERSATION_CHANGE_BY": {
      return { ...state, selectType: action.payload };
    }
    case "SELECTED_CONVERSATION_CHANGED": {
      const newState = { ...state };
      let friend = newState.conversations.find(
        (conversation) =>
          conversation.id === action.conversationId ||
          conversation._id === action.conversationId
      );
      if (friend) {
        newState.selectedConversation = newState.conversations.find(
          (conversation) => conversation.id === action.conversationId
        );
      } else {
        // add friend to db
        newState.selectedConversation = {
          id: action.conversationId,
          title: action.name,
          createdAt: new Date(),
          imageUrl: "",
          message: [],
          imageAlt: "",
          members: action.members,
        };
      }

      return newState;
    }
    case "DELETE_CONVERSATION": {
      if (state.selectedConversation) {
        const newState = { ...state };

        let selectedConversationIndex = newState.conversations.findIndex(
          (c) => c.id === newState.selectedConversation.id
        );
        newState.conversations.splice(selectedConversationIndex, 1);

        if (newState.conversations.length > 0) {
          if (selectedConversationIndex > 0) {
            --selectedConversationIndex;
          }

          newState.selectedConversation =
            newState.conversations[selectedConversationIndex];
        } else {
          newState.selectedConversation = null;
        }

        return newState;
      }

      return state;
    }
    case "DELETE_MESSAGE": {
      if (state.selectedConversation) {
        const newState = { ...state };
        // rreach at selectedconversation in conversations, need selected conversation id
        let selectedConversationIndex = newState.conversations.findIndex(
          (c) => c.id === newState.selectedConversation.id
        );
        // replace selected message in conversaion
        let filteredConversationMessage =
          newState.selectedConversation.messages.filter((item) => {
            return item.id != action.payload.id;
          });

        newState.selectedConversation = {
          ...newState.selectedConversation,
          latestMessageText: filteredConversationMessage[0]?.messageText || "",
          lastUpdate: filteredConversationMessage[0]?.createdAt || "",
          messages: filteredConversationMessage,
        };
        newState.conversations[selectedConversationIndex] =
          newState.selectedConversation;

        return newState;
      }

      return state;
    }
    case "NEW_MESSAGE_ADDED": {
      if (state.selectedConversation) {
        const newState = { ...state };
        if (newState.selectedConversation.messages.length > 0) {
          newState.selectedConversation?.messages?.unshift(action.textMessage);
        } else {
          newState.selectedConversation?.messages.push(action?.textMessage);
        }

        newState.conversations.forEach((item) => {
          if (item.id === state.selectedConversation.id) {
            item.createdAt = action.textMessage.createdAt;
            item.latestMessageText = action.textMessage.messageText;
            item.lastUpdate = new Date();
          }
        });
        newState.selectedConversation.createdAt = action.textMessage.createdAt;
        newState.selectedConversation.latestMessageText =
          action.textMessage.messageText;

        return newState;
      }

      return state;
    }

    case "NEW_MESSAGE_WATCHDOG": {
      let new_conversation = state.conversations;
      action.payload.data.forEach((chat) => {
        new_conversation.forEach((item) => {
          if (chat.from == item.id) {
            let isExist = false;
            item.messages.forEach((msg) => {
              if (msg.id === chat.id) {
                isExist = true;
              }
            });
            if (!isExist) {
              item.messages.unshift(chat);
              item.createdAt = chat.createdAt || "";
              item.latestMessageText = chat.messageText;
            }
          }
        });
      });

      return { ...state, conversation: new_conversation };
    }

    case "MESSAGE_SENT_ACKNOWLEDGED": {
      let conversation = state.conversations;
      conversation = conversation.map((item) => {
        if (item._id === action.userID) {
          item.messages = item.messages.map((eachMSG) => {
            if (eachMSG.id === action.messageID) {
              eachMSG.sentAcknowledged = true;
            }
            return eachMSG;
          });
        }
        return item;
      });
      return { ...state, conversations: conversation };
    }
    case "MESSAGES_FETCHED": {
      const { conversationId, messages, userId, hasMoreMessages } = action;
      console.log(
        "conversationId, messages, userId, hasMoreMessages",
        conversationId,
        messages,
        userId,
        hasMoreMessages
      );
      const currentConversationMapEntry = state.selectedConversation.messages;
      const newConversationMapEntry = { messages: [] };

      if (currentConversationMapEntry) {
        newConversationMapEntry.messages = [...currentConversationMapEntry];
      }
      let msg = messages?.map((item) => {
        if (parseInt(item.sender_id) === parseInt(userId)) {
          item = { ...item, isMyMessage: true };
          item = { ...item, sentAcknowledged: true };
        } else {
          item = { ...item, isMyMessage: false };
          item = { ...item, imageUrl: "" };
          item = { ...item, imageAlt: "" };
        }

        return item;
      });
      newConversationMapEntry.messages = [
        // ...newConversationMapEntry.messages,
        ...msg,
      ];
      // filtering only object
      // newConversationMapEntry.messages = newConversationMapEntry.messages.filter(item=> "messages" in item)
      // sorting

      newConversationMapEntry.messages.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      let newConversations = state.conversations;
      let newSelectedConversation = state.selectedConversation;
      newConversations.forEach((item) => {
        if (item.id === conversationId || item._id === conversationId) {
          item.messages = newConversationMapEntry.messages;
          item.hasMoreMessages = newConversationMapEntry.hasMoreMessages;
          item.isMessageFetched = true;
          newSelectedConversation = item;
        }
      });
      return {
        ...state,
        conversations: newConversations,
        selectedConversation: newSelectedConversation,
      };
    }
    default:
      return state;
  }
};

export default conversationsReducer;
