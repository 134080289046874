import { postToGetList } from "../../../components/crud";

export const conversationChanged = (conversationId, name = "") => ({
  type: "SELECTED_CONVERSATION_CHANGED",
  name,
  conversationId,
});

export const fetchConversation = (payload) => async (dispatch) => {
  return postToGetList("chat", {
    cid: payload.id,
    gid: payload.group_id,
    sid: payload.sid,
    rid: payload.otherPerson,
    sname: payload.sender_name,
    semail: payload.sender_email,
    rname: payload.receiver_name,
    remail: payload.receiver_email,
  })
    .then((resp) => {
      return dispatch({
        type: "MESSAGES_FETCHED",
        conversationId: payload.id,
        userId: payload.sid,
        messages: resp.data?.data?.messages,
        hasMoreMessages: "no",
      });
    })
    .catch((e) => {});
};

// data={friend:{id:3232, name:'fdf'}}
export const addNewFriend = (data) => (dispatch) => {
  return postToGetList("chat/friendship-create/", data)
    .then((resp) => {
      dispatch({
        type: "ADD_NEW_FRIEND",
        payload: resp.data.params.friends,
      });
      return resp.data;
    })
    .catch((e) => {
      console.log("error in friendship-create", e);
    });
};

export const conversationsRequested = () => ({
  type: "CONVERSATIONS_REQUESTED",
});

export const conversationDeleted = () => ({
  type: "DELETE_CONVERSATION",
});

export const messageDeleted = (message, selectedConversation) => (dispatch) => {
  if (selectedConversation) {
    // replace selected message in conversaion
    let filteredConversationMessage = selectedConversation.messages.filter(
      (item) => {
        return item.id != message.id;
      }
    );

    postToGetList("/chat/add", {
      conversationId: selectedConversation.id,
      messageID: message.id,
      type: "delete",
      sender_id: 123,
      receiver_id: 321,
      text: filteredConversationMessage[0]?.messageText || "",
      date: filteredConversationMessage[0]?.createdAt || "",
    })
      .then((resp) => {
        if (resp.data?.status == "success") {
          dispatch({
            type: "DELETE_MESSAGE",
            payload: message,
          });
        }
      })
      .catch((e) => {
        console.log("error in delete message", e);
      });
  }
};
export const newMessageAdded = (textMessage) => ({
  type: "NEW_MESSAGE_ADDED",
  textMessage: textMessage,
});

export const messagesRequested = (
  conversationId,
  numberOfMessages,
  lastMessageId
) => ({
  type: "MESSAGES_REQUESTED",
  payload: {
    conversationId,
    numberOfMessages,
    lastMessageId,
  },
});

export const messagesLoaded = (
  conversationId,
  messages,
  hasMoreMessages,
  lastMessageId
) => ({
  type: "MESSAGES_LOADED",
  payload: {
    conversationId,
    messages,
    hasMoreMessages,
    lastMessageId,
  },
});

export const fetchMyFriendList = (friendList) => ({
  type: "MY_FRIEND_LIST_FETCH",
  payload: friendList,
});

export const messageSentAcknowledged = (userID, messageID) => ({
  type: "MESSAGE_SENT_ACKNOWLEDGED",
  userID,
  messageID,
});

export const newMessageFromWatchdog = (message) => ({
  type: "NEW_MESSAGE_WATCHDOG",
  payload: message,
});
