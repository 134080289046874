import { useMemo } from "react";
import { setCookie } from "../../components/authentication/cookieManager.js";
import { connect } from "react-redux";
import { toggleSidebar } from "../../reducers/notification/action";
import { IconButton } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Close } from "@material-ui/icons";
import {
  Nav,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import styled from "styled-components";
import usePlatform from "../../customHooks/usePlatform.js";
import { Person, ExitToApp, AccountBoxOutlined } from "@material-ui/icons";
import ToastNotification from "../../components/commonComponent/tostNotification.jsx";
import { All_Page } from "./constant.js";

const DashboardNav = (props) => {
  const location = useLocation();
  const platform = usePlatform();
  const navigate = useNavigate();

  const pagename = useMemo(() => {
    const pathname = location.pathname;
    const page = All_Page.find((page) => pathname.startsWith(page.url));
    return page?.lable || "yogyarishte.com";
  }, [location.pathname]);

  const logoutme = () => {
    setCookie("access_token", null, 2);
    setCookie("role", null, 2);
    navigate("/");
  };

  return (
    <div>
      <ToastNotification />

      <NavBar
        is_mobile={platform === "mobile"}
        className="navbar dashbord-nav-custom  bg-white p-0 fixed-top-inner d-flex flex-row"
      >
        <h5 className="d-flex font-weight-bold nav-link pl-3 ml-2">
          {pagename}
        </h5>

        <div className="mr-1 d-flex">
          <IconButton
            className={`navbar-toggler  ${
              props.toggleSidebarStatus ? "navbar-mobile-toggler-show" : ""
            }`}
            id="navbar_global"
            onClick={props.toggleSidebar}
            sx={{
              border: props.toggleSidebarStatus ? "1px solid red" : "none",
              background: props.toggleSidebarStatus ? "#fff3f3" : "#fff",
            }}
            size="small"
          >
            {props.toggleSidebarStatus ? <Close color="error" /> : <Menu />}
          </IconButton>

          <UncontrolledDropdown nav>
            <DropdownToggle
              nav
              className="dropdown-toggle btn"
              id="navbar-user-dropdown"
            >
              <i className="ni ni-collection d-lg-none mr-1" />
              <span className="nav-link-inner--text">
                <Person /> {props.userDetail.userAccount.email}
              </span>
            </DropdownToggle>

            <DropdownMenu className="mr-6">
              <DropdownItem disabled>
                <span className="text-capitalize">
                  {props.userDetail?.userAccount?.role || "member"}
                </span>
              </DropdownItem>
              <DropdownItem divider />

              <DropdownItem to="profile-settings" tag={Link}>
                <AccountBoxOutlined /> Account
              </DropdownItem>
              <DropdownItem onClick={logoutme}>
                <ExitToApp /> Log Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </NavBar>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userDetail: state.user,
    toggleSidebarStatus: state.notification.toggleSidebar,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

const NavBar = styled(Nav)`
  box-shadow: ${({ is_mobile }) =>
    is_mobile
      ? "0 0 0"
      : "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"};
  border: ${({ is_mobile }) => (is_mobile ? "1px solid #E7E7F0" : "none")};
`;
export default connect(mapStateToProps, mapDispatchToProps)(DashboardNav);
