export const biodata_assential_fields = [
  "cast",
  "gotra",
  "height",
  "weight",
  "gender",
  "complexion",
  "dob",
  "birthPlace",
  "mangalik",
  "education",
  "institute",
  "occupation",
  "occupationDetail",
  "jobType",
  "income",
  "hobbies",
  "fatherName",
  "nativeVillage",
  "currentAddress",
  "paternalInfo",
  "motherName",
  "motherOccupation",
  "motherClan",
  "maternalPlace",
  "maternalFamilyInfo",
  "diet",
  "smoking",
  "drink",
];
