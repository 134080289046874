import {
  GET_USER_DETAIL,
  GET_ALL_USER,
  SET_SELECTED_USER,
  SET_SELECTED_ID,
  GET_THIS_USER,
  UPDATE_ACTIVITY_STATUS,
  GET_ME_ACCOUNT,
  SET_ACTIVE_DISPLAY_TITLE,
  GUEST_BIODATA,
  UPDATE_PROFILE,
  SET_MENU_ACCESS,
  SET_ACCOUNT_COMPLETION_PERCENTAGE,
} from "./user.action.js";

const initialState = {
  userData: {
    allData: {},
    allUser: [],
    userInfoInDetail: [],
    selectedUser: [],
    selectedID: null,
  },
  userAccount: {},
  activeDisplayTitle: "",
  accountCompletionPercentage: 0,
  guestBiodata: {},
  access: [],
  interestList: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAIL: {
      return {
        ...state,
        userData: { ...state.userData, allData: action.payload },
      };
    }
    case UPDATE_PROFILE: {
      let all_data = state.userData.allData;
      all_data["profile"] = action.payload;
      return { ...state, userData: { ...state.userData, allData: all_data } };
    }

    case GET_ME_ACCOUNT: {
      return { ...state, userAccount: action.payload };
    }
    case GET_ALL_USER: {
      let data = action.payload.map((item, index) => {
        return { ...item, ID: index };
      });
      return { ...state, userData: { ...state.userData, allUser: data } };
    }
    case GET_THIS_USER: {
      return {
        ...state,
        userData: { ...state.userData, userInfoInDetail: action.payload },
      };
    }
    case SET_SELECTED_USER: {
      return {
        ...state,
        userData: { ...state.userData, selectedUser: action.payload },
      };
    }
    case UPDATE_ACTIVITY_STATUS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          allData: { ...state.userData.allData, activity: action.payload },
        },
      };
    }
    case SET_SELECTED_ID: {
      return {
        ...state,
        userData: { ...state.userData, selectedID: action.payload },
      };
    }
    case SET_ACTIVE_DISPLAY_TITLE: {
      return { ...state, activeDisplayTitle: action.payload };
    }
    case SET_ACCOUNT_COMPLETION_PERCENTAGE: {
      return { ...state, accountCompletionPercentage: action.payload };
    }
    case GUEST_BIODATA: {
      return { ...state, guestBiodata: action.payload };
    }
    case SET_MENU_ACCESS: {
      return { ...state, access: action.payload };
    }
    case "SET_INTEREST_LIST": {
      return { ...state, interestList: action.payload };
    }
    default:
      return state;
  }
};

export default userReducer;
