import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import userReducer from "../reducers/user/user.reducer.js";
import conversationsReducer from "../reducers/messanger/reducers/conversations";
// import messagesReducer from '../reducers/messanger/reducers/messages';
import notificationReducer from "../reducers/notification/reducer";
import accessListReducer from "../reducers/accessList/access.reducer";
import subscriptionReducer from "../reducers/subscription/subscription.reducer";
import searchProfileReducer from "../reducers/search/searchProfile.reducer.js";

const masterReducer = combineReducers({
  user: userReducer,
  conversation: conversationsReducer,
  // messages: messagesReducer,
  notification: notificationReducer,
  menuAccessList: accessListReducer,
  subscription: subscriptionReducer,
  profileList: searchProfileReducer,
});

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (process.env.ENV !== "prod" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(
  masterReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
