import React,{useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from 'react-redux'
  
  function ToastNotification(){
    
    let toastIndication = useSelector(state=>state.notification?.toastNotification)
   
    useEffect(()=>{
        if(toastIndication.isShow){
         if(toastIndication.type=='success' || toastIndication.type=='Success'){
            notifySuccess(toastIndication.message)
         }else if(toastIndication.type=='error' || toastIndication.type=="Error"){
            notifyError(toastIndication.message)
         }else if(toastIndication.type=='info' || toastIndication.type=="Info"){
            notifyInfo(toastIndication.message)
         }
            
        }
    }, [toastIndication])

    const notifySuccess = (message) => toast.success( message || 'An Action Noticed!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });

    const notifyError = (message) => toast.error( message || 'An Action Noticed!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
    const notifyInfo = (message) => toast.info( message || 'An Action Noticed!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });

    return (
      <div className='postion-absolute'>
        <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      </div>
    );
  }

  export default ToastNotification