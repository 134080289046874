import PlatformContext from "./platformContext";
import { useWindowSize } from "react-use";

const PlatformProvider = ({ children }) => {
  const { width } = useWindowSize();
  return (
    <PlatformContext.Provider value={width < 500 ? "mobile" : "desktop"}>
      {children}
    </PlatformContext.Provider>
  );
};

export default PlatformProvider;
